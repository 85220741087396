import React from "react";
import Label from "./ui/Label.tsx";

interface Props {
  date: string;
  title: string;
  text: string;
  details?;
  location: string;
}

const CardCV = ({ date, title, text, details, location }: Props) => {
  return (
    <div className="card flex-column-s">
      <Label text={date} />
      <h3 className="heading-3">{title}</h3>
      <p className="p-medium">{text}</p>
      {details && <p className="p-medium text-grey">{details}</p>}
      <div className="flex-row-xs">
        <img src="/images/Icons/location-icon.png" alt="Pinnadel" />
        <p className="p-small">{location}</p>
      </div>
    </div>
  );
};

export default CardCV;
