import React from "react";
import Button from "../ui/Button.tsx";
import ProjectTeaser from "../ProjectTeaser.tsx";
import projectData from "../../data/projectData.tsx";

const ProjectsOverview = () => {
  const teaser = projectData
    .sort((a, b) => a.order - b.order)
    .slice(0, 3)
    .map((project) => {
      return (
        <ProjectTeaser
          key={project.link}
          title={project.title}
          description={project.description}
          link={project.link}
          imgPath={project.imgPath}
        />
      );
    });

  return (
    <div className="projects-overview container flex-column-l">
      <div className="projects-overview--header">
        <h2 className="heading-2">Projekte</h2>
        <Button label="Alle Projekte anzeigen" type="fill" link="projekte" />
      </div>
      <div className="projects-overview--container">{teaser}</div>
    </div>
  );
};

export default ProjectsOverview;
