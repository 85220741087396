import React from "react";
import Navbar from "../blocks/Navbar.tsx";
import Label from "../ui/Label.tsx";
import ProjectList from "../ProjectList.tsx";
import Footer from "../blocks/Footer.tsx";
import projectData from "../../data/projectData.tsx";

const Projekte = () => {
  const [filter, setFilter] = React.useState([
    { category: "UX / UI Design", filtered: false },
    { category: "Code", filtered: false },
    { category: "Motion Design", filtered: false },
  ]);

  const filterElements = filter.map((item) => {
    return (
      <Label
        key={item.category}
        type="search"
        text={item.category}
        isActive={item.filtered}
        handleClick={() => toggleFilter(item.category)}
      />
    );
  });

  // projects with the same category name if it is currently filtered
  const filteredProjects = projectData.filter((item) =>
    // loop through filters
    filter.some(
      (filter) => filter.category === item.category && filter.filtered
    )
  );

  const projectsElements =
    filteredProjects.length > 0
      ? filteredProjects
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            return (
              <ProjectList
                key={item.link}
                category={item.category}
                title={item.title}
                description={item.description}
                link={item.link}
                imgPath={item.imgPath}
              />
            );
          })
      : projectData
          // else show all projects
          .sort((a, b) => a.order - b.order)
          .map((item) => {
            return (
              <ProjectList
                key={item.link}
                category={item.category}
                title={item.title}
                description={item.description}
                link={item.link}
                imgPath={item.imgPath}
              />
            );
          });

  function toggleFilter(category) {
    // toggle the filtered state of given category
    setFilter((prevFilter) =>
      prevFilter.map((filter) => {
        return filter.category === category
          ? { ...filter, filtered: !filter.filtered }
          : filter;
      })
    );
  }

  return (
    <div>
      <Navbar />
      <div className="top-container">
        <div className="flex-column-l">
          <div className="flex-column-m">
            <h1 className="heading-1">Projekte</h1>
            <div className="flex-column-s">
              <p className="p-medium">Filtern nach:</p>
              <div className="flex-row-s" style={{ flexWrap: "wrap" }}>
                {filterElements}
              </div>
            </div>
          </div>
          {projectsElements}
        </div>
      </div>
      <Footer showImpressum={false} />
    </div>
  );
};

export default Projekte;
