import React from "react";
import CardCV from "../CardCV.tsx";
import cvData from "../../data/cvData.json";

const CV = () => {
  const cardsBildung = cvData
    .filter((item) => item.category === "Bildung")
    .map((item) => {
      return (
        <CardCV
          key={item.id}
          date={item.date}
          title={item.title}
          text={item.text}
          details={item.details}
          location={item.location}
        />
      );
    });
  const cardsEhrenamt = cvData
    .filter((item) => item.category === "Ehrenamt")
    .map((item) => {
      return (
        <CardCV
          key={item.id}
          date={item.date}
          title={item.title}
          text={item.text}
          details={item.details}
          location={item.location}
        />
      );
    });
  const cardsBerufserfahrung = cvData
    .filter((item) => item.category === "Berufserfahrung")
    .map((item) => {
      return (
        <CardCV
          key={item.id}
          date={item.date}
          title={item.title}
          text={item.text}
          details={item.details}
          location={item.location}
        />
      );
    });

  return (
    <div className="top-container flex-column-l">
      <h1 className="heading-1">Lebenslauf</h1>
      <div className="cv">
        <div className="flex-column-l">
          <div className="flex-column-m">
            <h2 className="heading-2">Bildung</h2>
            {cardsBildung}
          </div>
          <div className="flex-column-m">
            <h2 className="heading-2">Ehrenamt</h2>
            {cardsEhrenamt}
          </div>
        </div>
        <div className="flex-column-m">
          <h2 className="heading-2">Berufserfahrung</h2>
          {cardsBerufserfahrung}
        </div>
      </div>
    </div>
  );
};

export default CV;
