import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ showImpressum }) => {
  const d = new Date();
  const year = d.getFullYear();
  return (
    <div className="footer p-small text-primary flex-center flex-row-s">
      <p>Copyright {year}</p>
      {showImpressum && <Link to="impressum">Impressum</Link>}
    </div>
  );
};

export default Footer;
