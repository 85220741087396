import React from "react";
import Button from "../ui/Button.tsx";
import emailjs from "emailjs-com";

const Hero = () => {
  const [formData, setFormData] = React.useState({
    email: "",
    message: "",
  });

  const [status, setStatus] = React.useState("");

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevData) => {
      return {
        ...prevData,
        [name]: value,
      };
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    emailjs
      .send(
        "service_nzjaz48",
        "template_ejyvgtl",
        formData,
        "0VbAboTH9oZZfCRq9"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setStatus("Die E-Mail wurde erfolgreich versendet.");
          setFormData({ email: "", message: "" });

          setTimeout(() => setStatus(""), 3000);
        },
        (error) => {
          console.error("FAILED...", error);
          setStatus(
            "Ihre E-Mail konnte nicht versendet werden, bitte versuchen Sie es später erneut."
          );
          setTimeout(() => setStatus(""), 3000);
        }
      );
  }

  return (
    <div className="hero">
      <img
        className="hero--img"
        src="/images/Julia-Hellmann.png"
        alt="Hallo, ich bin Julia"
      ></img>

      <div className="flex-column-m hero--text">
        <h1 className="heading-display">Hi, ich bin Julia</h1>
        <p className="p-large">
          Als Designer und Developer vereine ich Kreativität und technische
          Expertise, um einzigartige digitale Lösungen zu schaffen. Mein Fokus
          liegt dabei auf der nutzerzentrierten Gestaltung.
        </p>
        <form onSubmit={handleSubmit} className="flex-column-s">
          <div className="flex-column-xs">
            <input
              className="p-medium"
              type="email"
              placeholder="E-Mail"
              name="email"
              onChange={handleChange}
              value={formData.email}
              required
            />
            <textarea
              className="p-medium"
              placeholder="Nachricht"
              name="message"
              onChange={handleChange}
              value={formData.message}
              required
            />
          </div>

          <Button label="Kontakt aufnehmen" type="fill" />
        </form>

        {/* Success/Failure Message */}
        {status && <div className="p-small text-primary status">{status}</div>}
      </div>
    </div>
  );
};

export default Hero;
