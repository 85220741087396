import React from "react";
import Label from "./ui/Label.tsx";

interface Props {
  skill: string;
  knowledge: string;
  showLogo: boolean;
  logo?: string;
}

const Skill = ({ skill, knowledge, showLogo, logo }: Props) => {
  return (
    <div
      className={
        showLogo ? "skill flex-row-s" : "skill skill-nologo flex-row-s"
      }
    >
      {showLogo && (
        <img
          className="skill--logo"
          src={"/images/Icons/" + logo}
          alt={skill}
        />
      )}
      <div className="flex-column-xs">
        <h4 className="heading-5">{skill}</h4>
        <Label text={knowledge} type="default" isActive={false} />
      </div>
    </div>
  );
};

export default Skill;
