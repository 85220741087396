import React from "react";
import ReactPlayer from "react-player/lazy";
import Navbar from "../../blocks/Navbar.tsx";
import ProjectHero from "../../blocks/ProjectHero.tsx";
import ProjectDetails from "../../blocks/ProjectDetails.tsx";

const MetaAds = () => {
  return (
    <div>
      <Navbar />
      <ProjectHero imgPath="/images/projekte/meta/meta.png" />

      <div className="flex-row-l project--container">
        <ProjectDetails
          team={["Julia Hellmann"]}
          kontext={["Arbeitsprojekt", "November 2024"]}
        />

        <div className="flex-column-s project-details--text">
          <h1 className="heading-3">Meta Ads</h1>
          <h2 className="heading-2">Ziel der Kampagne</h2>

          <p className="p-medium">
            Die über Meta verbreitete Werbekampagne ist eine animierte
            Stellenanzeige, die sich an Ärzte richtet. Das Unternehmen ist ein
            Personaldienstleister im medizinischen Bereich und sucht mit dieser
            allgemeinen Anzeige nach neuen Mitarbeitern. Inhaltlich wird auf das
            Gehalt und einige Vorteile der Firma eingegangen. Zudem wird auf den
            einfachen Bewerbungsweg über ein Formular eingegangen.
          </p>
        </div>
      </div>

      <div className="flex-column-l" style={{ paddingBottom: "64px" }}>
        <div className="content-container">
          <div className="flex-column-s">
            <h2 className="heading-3">Erstellung der Anzeige</h2>
            <p className="p-medium">
              Die Anzeige wurde zuerst in Figma gestaltet und nachträglich mit
              Adobe After Effects animiert.
            </p>
          </div>
        </div>

        <div className="content-container">
          <div className="col-33 flex-column-s">
            <h2 className="heading-3">Gestaltung</h2>
            <p className="p-medium">
              Beim Design wurde sich an die Corporate Identity der Marke
              gehalten und Bilder und Farben verwendet. Da die Kampagne an
              verschiedenen Stellen ausgespielt werden soll, wird das Video in
              verschiedenen Formaten benötigt. Dazu zählen das Quadrat, Story
              und Querformat. Außerdem wurden verschiedene Varianten für die
              unterschiedlichen Fachbereiche der Ärzte erstellt, bei denen es
              Text- und Farbanpassungen und verschiedene Bilder gibt.
            </p>
          </div>

          <div className="col-33">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/meta/meta-formate.png)`,
              }}
            ></div>
          </div>

          <div className="col-33">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/meta/meta-varianten.png)`,
              }}
            ></div>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Nutzen von Essential Graphics</h2>
            <p className="p-medium">
              Bei der Erstellung in After Effects wurde die Anzeige einmalig
              erstellt und über die Essential Graphics Funktion in den
              verschiedenen Varianten adaptiert. So ist es möglich einzelne
              Texte, Farben und Bilder zu tauschen ohne Animationen
              dementsprechend oft aufzubauen. Bei nachträglichen Änderungen oder
              Erstellung weiterer Varianten wird somit viel Zeit eingespart.
              Durch Nutzung von Expressions passen sich auch Kästen an die
              Textlänge automatisch an.
            </p>
          </div>

          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/meta/meta-essential-graphics.png)`,
              }}
            />
          </div>
        </div>

        <div className="content-container">
          <div className="col-33">
            <ReactPlayer
              url="https://youtu.be/Zfx42EpWxRQ"
              controls={true}
              width="100%"
            />
          </div>
          <div className="col-33">
            <ReactPlayer
              url="https://youtu.be/dYjYhlT_8bM"
              controls={true}
              width="100%"
            />
          </div>
          <div className="col-33">
            <ReactPlayer
              url="https://youtu.be/rlhzP7Y8BOU"
              controls={true}
              width="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaAds;
