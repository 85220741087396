import React from "react";
import Navbar from "../../blocks/Navbar.tsx";
import ProjectHero from "../../blocks/ProjectHero.tsx";
import ProjectDetails from "../../blocks/ProjectDetails.tsx";

const Profectum = () => {
  return (
    <div>
      <Navbar />
      <ProjectHero imgPath="/images/projekte/profectum/profectum.png" />

      <div className="flex-row-l project--container">
        <ProjectDetails
          team={["Antje Schmid", "Fiona Philipp", "Julia Hellmann"]}
          kontext={[
            "Bachelor Projekt",
            "März bis Juli 2021",
            "Hochschule für Gestaltung",
            "Schwäbsich Gmünd",
          ]}
        />

        <div className="flex-column-s project-details--text">
          <div>
            <h1 className="heading-3">profectum</h1>
            <h2 className="heading-2">
              Modulares System zur Unter&shy;stützung der
              Medikamenten&shy;einnahme
            </h2>
          </div>
          <p className="p-medium">
            profectum ist eine Plattform, die schnell zugänglich ist und durch
            verschiedene Module die Medikamenteneinnahme übersichtlicher
            gestaltet. Daten zu Medikamenten und anderen medizinischen Begriffen
            werden klar und einfach verständlich für den Nutzer aufgelistet.
            Zudem werden Wechselwirkungen durch einen Check vermieden und über
            Warnungen und Hinweise können Probleme schneller aufgeklärt werden.
            Alle Dokumente, wie Kontaktdaten, Arzttermine und Diagnosen, sind an
            einem Ort und zeigen die persönliche Gesundheitshistorie.
          </p>
        </div>
      </div>

      <div className="flex-column-l" style={{ paddingBottom: "64px" }}>
        <div className="content-container">
          <div className="col-60 flex-column-s">
            <h2 className="heading-3">Problemstellung</h2>
            <p className="p-medium">
              35.000 Menschen sterben jährlich an den Folgen einer falschen
              Medikation allein in Deutschland. Gründe dafür sind unter anderem
              Unsicherheiten in der Einnahme, das Vergessen wichtiger
              Medikamente und eine zunehmende Selbstmedikation, bei der
              Patienten Medikamente in eigenem Ermessen absetzen oder durch
              ihren Lebensstil die Wirksamkeit verändern. Auch eine schlechte
              Kommunikation zwischen Ärzten untereinander, welche zu doppelten
              oder nicht zueinander passenden Verschreibungen führen, können
              eine entscheidende Rolle spielen. Durch Medikationspläne und
              Beipackzettel wird versucht, der Problematik entgegenzuwirken.
              Diese sind jedoch hauptsächlich in Papierform erhältlich und
              dadurch nicht sonderlich flexibel. Der Beipackzettel ist schwer
              lesbar, schnell veraltet und Informationen werden überlesen oder
              gänzlich ignoriert. Beim Medikationsplan ist die fehlende
              Kommunikation zwischen verschiedenen Fachärzten und der fehlende
              Einbezug von persönlichen Medikamenteneinnahmen und Lebensmitteln
              ein Problem.
            </p>
          </div>
          <div className="col-40 quote">
            <h3 className="heading-4">
              Wie können wir Patienten, die viele Medikamente gleichzeitig
              einnehmen, beim frühzeitigen Erkennen von Wechselwirkungen
              unterstützen?
            </h3>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/profectum/profectum-startseite.png)`,
              }}
            />
          </div>
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Konzept</h2>
            <p className="p-medium">
              Zur Vermeidung von Problemen bei der Medikamenteneinnahme wurde
              das modulare System profectum konzipiert. Zur besseren
              Zugänglichkeit sind einige Module, die allgemeine Informationen zu
              Medikamenten und Wechselwirkungen ausgeben, ohne Registrierung und
              Nutzerkonto verfügbar. Es wurde darauf geachtet, dass profectum
              sowohl von älteren als auch jüngeren Generationen genutzt werden
              kann, da ältere Generationen häufig viele Medikamente gleichzeitig
              einnehmen und jüngere Generationen so früh wie möglich aufgeklärt
              werden sollten, um Unwissenheitsfehler möglichst zu vermeiden.
            </p>
          </div>
        </div>

        <div className="content-container">
          <div className="flex-column-l">
            <h2 className="heading-3">Module</h2>
            <div className="content-container-0">
              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-beipackzettel.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Beipackzettel</h4>
                  <p className="p-small">
                    Der Beipackzettel bietet allgemeine Informationen zu einem
                    gesuchten Medikament in einem übersichtlichen und einfach
                    verständlichen Format. Bei einem eingeloggten Nutzer werden
                    zusätzlich persönliche Warnungen ausgegeben, die auf
                    Wechselwirkungen, Allergien und sonstige Probleme eingehen.
                  </p>
                </div>
              </div>

              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-wechselwirkungscheck.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Wechselwirkungscheck</h4>
                  <p className="p-small">
                    Der Check dient zur schnellen Übersicht, ob Medikamente
                    gemeinsam eingenommen werden können. Durch Farbcodierungen
                    wird der Schweregrad der Wechselwirkungen angegeben. Bei
                    einer Warnung werden zusätzliche Informationen zu möglichen
                    Folgen und Empfehlungen ausgegeben.
                  </p>
                </div>
              </div>

              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-wissenswertes.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Wissenswertes</h4>
                  <p className="p-small">
                    Den Nutzern werden interessante Artikel zum Thema
                    Gesundheit, Krankheit und Medikamente ausgegeben. So kann
                    auf mögliche Fragen eingegangen werden und aktuell relevante
                    Themen behandelt werden.
                  </p>
                </div>
              </div>
            </div>

            <div className="content-container-0">
              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-medikationsplan.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Medikationsplan</h4>
                  <p className="p-small">
                    Der Medikationsplan listet die einzunehmenden Medikamente
                    auf und zeigt auch, welche Medikamente als Nächstes
                    eingenommen werden sollen. Zur besseren Kompatibilität mit
                    zurzeit gängigen analogen Medikationsplänen, können diese
                    eingescannt werden und auch der digital aufbereitete Plan
                    kann exportiert und gedruckt werden.
                  </p>
                </div>
              </div>

              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-verlauf.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Mein Verlauf</h4>
                  <p className="p-small">
                    Hier werden Termine, Diagnosen, Dokumente und
                    Medikamenteneinnahmen aufgelistet. Dies dient als
                    persönliche Gesundheitshistorie, die Ärzten Aufschlüsse auf
                    mögliche Folgen durch die Medikation geben kann, und als
                    Speicherort für Zugangsdaten zu Online-Befunden.
                  </p>
                </div>
              </div>

              <div className="col-33 flex-column-m">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/profectum/profectum-aerzte.png)`,
                  }}
                ></div>
                <div className="flex-column-s">
                  <h4 className="heading-5">Ärzte</h4>
                  <p className="p-small">
                    In diesem Modul werden alle Kontaktdaten von Ärzten und den
                    zugehörigen Terminen gesammelt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/profectum/profectum-profil.png)`,
              }}
            />
          </div>
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Vorteile des Nutzerkontos</h2>
            <p className="p-medium">
              Bei profectum kann ein Profil mit persönlichen Daten zu
              Vorerkrankungen, Allergien, Intoleranzen und Konsum erstellt
              werden. Außerdem können Module, wie der Medikationsplan und
              Verlauf genutzt werden. Diese helfen durch Hinweise und Warnungen
              Wechselwirkungen zu vermeiden und über mögliche Probleme
              aufzuklären.
            </p>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Mehrwert</h2>
            <p className="p-medium">
              profectum vereint eine Vielzahl von bereits existierenden
              Werkzeugen, entwickelt sie nutzerfreundlich weiter und stimmt sie
              aufeinander ab. Alle Module sind miteinander verknüpft und
              profitieren von den in anderen Modulen hinterlegten Daten. Im
              Gegensatz zu den analogen, bereits vorhandenen Produkten, wie der
              Medikationsplan, Beipackzettel und Verlauf, ist profectum stetig
              und schnell aktualisierbar. So können auch spezifische Warnungen,
              wie Rückrufaktionen, unabhängig angezeigt werden. Ob ein
              Medikament eingenommen werden darf, ist übersichtlich und auf
              einen Blick erkennbar. Zudem werden auch Alternativen zu
              Medikamenten vorgeschlagen. Fachbegriffe sind hervorgehoben und
              bieten weitere Erklärungen zu ihrem Inhalt. profectum ist zudem
              auch besonders zugänglich, da einige Module auch ohne Konto
              nutzbar sind und das System auf verschieden Plattformen
              beziehungsweise Geräten verfügbar ist.
            </p>
          </div>

          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/profectum/profectum-responsive.png)`,
              }}
            />
          </div>
        </div>

        <div className="content-container">
          <div className="flex-column-s">
            <h2 className="heading-3">Ausblick</h2>
            <p className="p-medium">
              Mit profectum im jetzigen Stand kann der Patient Wechselwirkungen
              verhindern, muss sich danach jedoch mit dem behandelnden Arzt
              absprechen und ein neues Rezept muss ggf. verschrieben werden. Da
              Ärzte allerdings verschiedene Software benutzen ist es nicht
              einfach eine einheitliche Lösung zu finden, um bereits hier
              anzusetzen. Zukünftig könnte beispielsweise ein profectum-Plugin
              entwickelt werden, welches mit allen gängigen Softwares der Ärzte
              kompatibel ist und die Wechselwirkungen unter Zustimmung des
              Patienten bereits erkennt bevor ein Rezept überhaupt ausgestellt
              wurde. Zukünftig können wir uns auch eine Einbindung von der
              digitalen Patientenakte und dem e-Rezept - die sich zurzeit in der
              Testphase befinden - vorstellen. Auch eine Funktion, die mehrere
              Profile von Mitgliedern eines Haushalts mit deren persönlichen
              Daten umfasst, empfinden wir als sinnvoll.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profectum;
