import React from "react";
import Label from "./ui/Label.tsx";
import Button from "./ui/Button.tsx";

const ProjektList = ({ title, description, link, imgPath, category }) => {
  return (
    <div className="flex-column-l">
      <div className="project-list">
        <img className="project-list--img" src={imgPath} alt={title} />
        <div className="project-list--text flex-column-m">
          <Label type="default" isActive={false} text={category} />
          <div className="flex-column-s">
            <h3 className="heading-3">{title}</h3>
            <p className="p-medium">{description}</p>
          </div>
          <Button
            label="Mehr erfahren"
            type="outline"
            link={"/projekte/" + link}
          />
        </div>
      </div>
      <hr className="project-list--line" />
    </div>
  );
};

export default ProjektList;
