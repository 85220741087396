import React from "react";
import Navbar from "../blocks/Navbar.tsx";
import Footer from "../blocks/Footer.tsx";
import Button from "../ui/Button.tsx";

const NoPage = () => {
  return (
    <div>
      <Navbar />
      <div
        className="top-container flex-column-s"
        style={{
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1>Die Seite, die du suchst, scheint nicht zu existieren.</h1>
        <Button type="fill" label="Zur Startseite" link="/" />
      </div>
      <Footer showImpressum={false} />
    </div>
  );
};

export default NoPage;
