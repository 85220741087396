import React from "react";
import Button from "./ui/Button.tsx";

const ProjectTeaser = ({ title, description, link, imgPath }) => {
  return (
    <div className="project-teaser flex-column-m">
      <img className="project-teaser--img" src={imgPath} alt={title} />
      <div className="flex-column-xs">
        <h4 className="heading-4">{title}</h4>
        <p className="p-medium">{description}</p>
      </div>
      <Button label="Mehr erfahren" type="outline" link={"/projekte/" + link} />
    </div>
  );
};

export default ProjectTeaser;
