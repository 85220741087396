import React from "react";

const ProjectHero = ({ imgPath }) => {
  const imgClass = {
    backgroundImage: `url(${imgPath})`,
  };
  return (
    <div className="top-container">
      <div className="project-hero--img" style={imgClass}></div>
    </div>
  );
};

export default ProjectHero;
