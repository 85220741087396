import React from "react";

const Images = () => {
  return (
    <div className="container flex-column-s">
      <h1 className="heading-1">Persönliche Einblicke</h1>
      <div className="insights-row">
        <div className="insights-column ">
          <img src="/images/img-grid/img-31.png" alt="Pinguin gehäkelt" />
          <img src="/images/img-grid/img-32.png" alt="Julia" />
        </div>

        <div className="insights-column">
          <img src="/images/img-grid/img-22.png" alt="selbstgebackene Brezen" />
          <img src="/images/img-grid/img-21.png" alt="Minigolf" />
        </div>
        <div className="insights-column last-row">
          <img src="/images/img-grid/img-11.png" alt="beim Wandern" />
          <img src="/images/img-grid/img-12.png" alt="beim Ski fahren" />
        </div>
      </div>
    </div>
  );
};

export default Images;
