import React from "react";
import Navbar from "../../blocks/Navbar.tsx";
import ProjectHero from "../../blocks/ProjectHero.tsx";
import ProjectDetails from "../../blocks/ProjectDetails.tsx";

const Aqui = () => {
  return (
    <div>
      <Navbar />
      <ProjectHero imgPath="/images/projekte/aqui/aqui.png" />

      <div className="flex-row-l project--container">
        <ProjectDetails
          team={[
            "Christopher Himann",
            "Noah Engler",
            "Catherine Haentges",
            "Isabella Wolf",
            "Julia Hellmann",
          ]}
          kontext={[
            "Hochschulprojekt | 3. Semester",
            "März bis Juli 2019",
            "Hochschule für Gestaltung",
            "Schwäbsich Gmünd",
          ]}
        />

        <div className="flex-column-s project-details--text">
          <div>
            <h1 className="heading-3">Aqui</h1>
            <h2 className="heading-2">
              Wasserroboter zur Sicherung der Wasserqualität
            </h2>
          </div>
          <p className="p-medium">
            Der autonome Wasserroboter bewegt sich autonom entlang ihm
            vorgegebenen Punkten in regelmäßigen Abständen. An diesen Punkten
            misst er die Werte in verschiedenen Wassertiefen und speichert die
            Daten in einer Datenbank. Die aktuellen Angaben können von Besuchern
            des Sees und auch für Langzeitstudien zum ökologischen
            Gleichgewichts des Gewässers genutzt werden. Bisherige Wasserproben
            werden selten genommen und brauchen sehr lange, da sie im Labor
            untersucht werden. Aqui hingegen misst Echtzeitdaten, die für den
            Nutzer einsehbar sind.
          </p>
        </div>
      </div>

      <div className="flex-column-l" style={{ paddingBottom: "64px" }}>
        <div className="content-container">
          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/aqui/aqui-technik.png)`,
              }}
            />
          </div>
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Technischer Aufbau</h2>
            <p className="p-medium">
              Die über Ardiuno gesteuerten Sensoren sitzen in einer Kapsel, die
              mit einem Steppermotor nach unten gelassen wird. Die Messwerte
              werden in unterschiedlichen Tiefen ausgelesen und via Bluetooth an
              eine Datenbank weitergeleitet. Die Website greift auf die Werte in
              der Datenbank zu und macht diese im Frontend für den Nutzer mit
              Grafen sichtbar und verständlich. Der Roboter wird durch
              Solarenergie angetrieben und steht in ständiger Verbindung mit der
              Website, damit die Grafen stehts aktuell sind. AQUI wurde
              prototypisch von unserem Team, bestehend aus Produktgestaltern und
              Creative Technologists, umgesetzt.
            </p>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50 flex-column-s">
            <h2 className="heading-3">Grafische Oberfläche</h2>
            <p className="p-medium">
              Die gesammelten Sensordaten werden auf einer Webseite angezeigt.
              Jeder Wert wird in Echtzeit aktualisiert und für den Nutzer durch
              Farbcodierungen zugänglich gemacht. Auf einer Karte werden alle
              Seen, von denen Daten existieren, angezeigt. Pro See gibt es eine
              Übersichtsseite mit Informationen, was die Werte bedeuten und auch
              eine Detailseite pro Sensorwert, auf der weitere Informationen
              dargestellt werden. Das Layout wurde in Adobe XD entworfen und mit
              verschiedenen Frameworks von Javascript, zum Beispiel chart.js,
              auch realisiert.
            </p>
          </div>

          <div className="col-50">
            <div
              className="content-img"
              style={{
                backgroundImage: `url(/images/projekte/aqui/aqui-design.png)`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aqui;
