import React from "react";
import Navbar from "../../blocks/Navbar.tsx";
import ProjectHero from "../../blocks/ProjectHero.tsx";
import ProjectDetails from "../../blocks/ProjectDetails.tsx";

const LeafLearn = () => {
  return (
    <div>
      <Navbar />
      <ProjectHero imgPath="/images/projekte/leaflearn/leaflearn.png" />

      <div className="flex-row-l project--container">
        <ProjectDetails
          team={["Julia Hellmann"]}
          kontext={["Eigenständiges Projekt", "Oktober bis Dezember 2024"]}
        />

        <div className="flex-column-s project-details--text">
          <div>
            <h1 className="heading-3">LeafLearn</h1>
            <h2 className="heading-2">Das Markenkonzept</h2>
          </div>
          <p className="p-medium">
            Die Marke LeafLearn bietet Erwachsenen Kurse für lebenslanges Lernen
            an. Der Fokus liegt dabei auf der beruflichen und persönlichen
            Weiterbildung beziehungsweise dem Lernen neuer Fähigkeiten. Die
            Kurse sollen mit Zertifikaten abgeschlossen werden können, was die
            Seriosität der Marke zeigt und das Vertrauen der Zielgruppe erhöht.
            Das Markenkonzept basiert außerdem auf Klimafreundlichkeit und
            sozialer Gerechtigkeit, denn beim Kauf eines Kurses werden
            Bildungsprojekte für benachteiligte Gemeinschaften und nachhaltige
            Initiativen gefördert. Das stärkt die Marke emotional und schafft
            Vertrauen bei der Zielgruppe.
          </p>
        </div>
      </div>

      <div className="flex-column-l" style={{ paddingBottom: "64px" }}>
        <div className="content-container">
          <div className="quote flex-column-s">
            <h3 className="heading-4">
              “Deine Zukunft beginnt mit neuen Fähigkeiten”
            </h3>
            <p>Slogan von LeafLearn</p>
          </div>
        </div>

        <div className="content-container">
          <div className="flex-column-s">
            <h2 className="heading-3">Gestaltungskonzept</h2>
            <div className="content-container-0">
              <div className="col-50 flex-column-s">
                <h3 className="heading-5">Farben</h3>
                <p className="p-medium">
                  Die Markenfarben bestehen aus verschiedenen Grüntönen und
                  ruhigen, neutralen Farben. Es wurde darauf geachtet, dass die
                  Farben gut miteinander harmonieren und eine gute Lesbarkeit
                  durch ausreichend Kontrast sicherstellen.
                </p>
              </div>
              <div className="col-50">
                <div
                  className="content-img"
                  style={{
                    backgroundImage: `url(/images/projekte/leaflearn/leaflearn-farben.png)`,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="col-50 flex-column-s">
            <h3 className="heading-5">Formen</h3>
            <p className="p-medium">
              Die geometrischen Formen sind vielfältig einsetzbar und erinnern
              an Schule und Lernen. Verschiedene Kombinationen ermöglichen eine
              sehr flexible Gestaltung. Die Formen können als Maske für Bilder
              oder als Farbflächen genutzt werden. Dies ermöglicht eine
              konsistente und gleichzeitig abwechslungsreiche Markenidentität.
            </p>
          </div>

          <div className="col-50 flex-column-s">
            <h3 className="heading-5">Typografie</h3>
            <p className="p-medium">
              Die Schrift soll klar und lesbar für den Nutzer sein, da die
              Zielgruppe auch ältere Menschen beinhaltet. Für Überschriften wird
              eine zusätzliche Schriftart verwendet, die durch die Gestaltung an
              die geometrischen Formen erinnert.
            </p>
          </div>
        </div>

        <div className="content-container">
          <div className=" content-container-0">
            <div className="col-50">
              <div
                className="content-img"
                style={{
                  backgroundImage: `url(/images/projekte/leaflearn/leaflearn-bildwelt.png)`,
                }}
              />
            </div>
            <div className="col-50 flex-column-s">
              <h3 className="heading-5">Bildwelt</h3>
              <p className="p-medium">
                Die Bilder sollen die Stimmung der Marke einfangen und mit
                Einbindung natürlicher, grüner Elemente an die
                Naturverbundenheit der Marke erinnern. Außerdem ist es wichtig,
                verschiedene Generationen darzustellen und möglichst natürliche
                und authentische Momente einzufangen.
              </p>
            </div>
          </div>
        </div>

        <div className="content-container">
          <div className="content-container-0">
            <div className="col-33 flex-column-m">
              <div
                className="content-img"
                style={{
                  backgroundImage: `url(/images/projekte/leaflearn/leaflearn-website.png)`,
                }}
              ></div>
              <div className="flex-column-s">
                <h4 className="heading-5">Website</h4>
                <p className="p-small">
                  Beim Gestalten der Website sieht man die Vielfältigkeit der
                  geometrischen Formen in den Inhaltsblöcken. Die Startseite
                  zeigt die Marke, stellt sie vor und gibt Einblicke, welche
                  Kurse und welchen Mehrwert die Marke bietet. Wichtig für die
                  Gestaltung ist eine gute Nutzerführung, Lesbarkeit und
                  verständliche Vermittlung der Werte und Inhalte. Bei der
                  Erstellung der Blöcke wurde auch auf die mobile Darstellung
                  geachtet, damit die Website auf verschiedenen Geräten auch
                  verfügbar sein kann.
                </p>
              </div>
            </div>

            <div className="col-33 flex-column-m">
              <div
                className="content-img"
                style={{
                  backgroundImage: `url(/images/projekte/leaflearn/leaflearn-instagram.png)`,
                }}
              ></div>
              <div className="flex-column-s">
                <h4 className="heading-5">Social Media</h4>
                <p className="p-small">
                  Für eine Social Media Präsenz wurde ein Feed für Instagram
                  gestaltet. Hier soll über die Marke an sich aufgeklärt und
                  verschiedene Kurse vorgestellt werden. Das ansprechende Design
                  soll Nutzer zur Interaktion mit der Marke bringen und diese
                  erlebbar machen, unter anderem können auch Geschichten von
                  Lernenden oder unterstützten Projekten erzählt werden.
                </p>
              </div>
            </div>

            <div className="col-33 flex-column-m">
              <div
                className="content-img"
                style={{
                  backgroundImage: `url(/images/projekte/leaflearn/leaflearn-branding.png)`,
                }}
              ></div>
              <div className="flex-column-s">
                <h4 className="heading-5">Werbeartikel</h4>
                <p className="p-small">
                  Für das Konzept wurden auch Werbeartikel konzipiert, die als
                  Kundengeschenke gedacht sind. Dabei wurde auch auf die Auswahl
                  der Produkte geachtet. Das Notizbuch und die Sticker können
                  beim Lernen eingesetzt werden und haben deswegen einen
                  praktischen Nutzen und steigern gleichzeitig die Brand
                  Awareness. Die wiederverwendbare Baumwolltasche steht für die
                  klimafreundliche Marke.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeafLearn;
