import React from "react";
import { Link } from "react-router-dom";

interface Props {
  type: "outline" | "fill";
  label: string;
  link?: string;
  handleClick?: VoidFunction;
}

const Button = ({ type, label, link, handleClick }: Props) => {
  const button = link ? (
    <Link to={link}>
      <button
        type="button"
        className={"p-medium btn" + (type === "outline" ? " btn-outline" : "")}
      >
        <div>{label}</div>
      </button>
    </Link>
  ) : (
    <button
      type="submit"
      onClick={handleClick}
      className={"p-medium btn" + (type === "outline" ? " btn-outline" : "")}
    >
      <div>{label}</div>
    </button>
  );

  return button;
};

export default Button;
