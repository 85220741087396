import React from "react";
import Button from "./ui/Button.tsx";

const Popup = () => {
  const [displayPopup, setDisplayPopup] = React.useState(true);

  function closePopup() {
    localStorage.setItem("seenPopup", JSON.stringify(true));
    setDisplayPopup(false);
  }

  // get localStorage data to see if user has seen the popup
  React.useEffect(() => {
    let returningUser = localStorage.getItem("seenPopup");
    returningUser !== null && setDisplayPopup(!JSON.parse(returningUser));
  }, []);

  return (
    <div>
      {displayPopup && (
        <div className="popup-container">
          <div className="popup--background"></div>
          <div className="popup">
            {/* <div className="popup--img"></div> */}
            <div className="flex-column-s ">
              <h2 className="heading-2">Cookies!</h2>
              <p className="p-medium">
                Diese Website nutzt Cookies, um die Nutzererfahrung zu
                verbessern.
              </p>
              <Button type="fill" label="Okay" handleClick={closePopup} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Popup;
