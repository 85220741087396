import { ComponentType } from "react";
import Aqui from "../components/pages/Projekte/Aqui.tsx";
import Profectum from "../components/pages/Projekte/Profectum.tsx";
import MetaAds from "../components/pages/Projekte/MetaAds.tsx";
import LeafLearn from "../components/pages/Projekte/LeafLearn.tsx";

interface ProjectProps {
  title: string;
  category: string;
  description: string;
  link: string;
  component: ComponentType<any>;
  imgPath: string;
  order: number;
}

const projectData: ProjectProps[] = [
  {
    order: 1,
    title: "profectum",
    category: "UX / UI Design",
    description:
      "Das modulare System zur Unterstützung der Medikamenten\u00ADeinnahme und zur Vermeidung von Wechsel\u00ADwirkungen ist im Rahmen meiner Bachelor\u00ADarbeit entstanden.",
    link: "profectum",
    component: Profectum,
    imgPath: "/images/projekte/profectum/profectum.png",
  },
  {
    order: 4,
    title: "Aqui",
    category: "Code",
    description:
      "Der Wasser\u00ADroboter sammelt Daten zur Sicherung der Wasser\u00ADqualität, indem er autonom über Seen fährt und Daten in verschiedenen Wasser\u00ADtiefen misst.",
    link: "aqui",
    component: Aqui,
    imgPath: "/images/projekte/aqui/aqui.png",
  },
  {
    order: 3,
    title: "Meta Ads Kampagne",
    category: "Motion Design",
    description:
      "Eine Video-Werbekampagne, die über Meta verbreitet wird, mit mehreren Formaten und inhaltlichen Varianten. Die Stellen\u00ADanzeige richtet sich an Ärzte.",
    link: "meta-ads",
    component: MetaAds,
    imgPath: "/images/projekte/meta/meta.png",
  },
  {
    order: 2,
    title: "LeafLearn",
    category: "UX / UI Design",
    description:
      "Branding Konzept für die fiktive Marke “LeafLearn”, die verschiedene Kurse für lebens\u00ADlanges Lernen anbietet. Gestaltet wurde eine Website, eine Präsentation auf Social Media und verschiedene physische Produkte, um die Marke zu repräsentieren.",
    link: "leaf-learn",
    component: LeafLearn,
    imgPath: "/images/projekte/leaflearn/leaflearn.png",
  },
];

export default projectData;
