import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import WebFont from "webfontloader";
import Home from "./components/pages/Home.tsx";
import Projekte from "./components/pages/Projekte.tsx";
import Lebenslauf from "./components/pages/Lebenslauf.tsx";
import Impressum from "./components/pages/Impressum.tsx";
import NoPage from "./components/pages/NoPage.tsx";
import projectData from "./data/projectData.tsx";

function App() {
  // load fonts
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Poppins"],
      },
    });
  }, []);

  // Projekt Links
  const projectLinks = projectData.map((project) => {
    return (
      <Route
        key={project.link}
        path={`/projekte/${project.link}`}
        Component={project.component}
      />
    );
  });

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/projekte" Component={Projekte} />
          {projectLinks}
          <Route path="/lebenslauf" Component={Lebenslauf} />
          <Route path="/impressum" Component={Impressum} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
