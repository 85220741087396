import React from "react";

const CardSimple = ({ title, text }) => {
  return (
    <div className="card card-simple flex-column-s">
      <h3 className="heading-5 text-primary">{title}</h3>
      <p className="p-medium">{text}</p>
    </div>
  );
};

export default CardSimple;
