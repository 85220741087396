import React from "react";
import Navbar from "../blocks/Navbar.tsx";
import CV from "../blocks/CV.tsx";
import Skills from "../blocks/Skills.tsx";
import HardSkills from "../blocks/HardSkills.tsx";
import SoftSkills from "../blocks/SoftSkills.tsx";
import Images from "../blocks/Images.tsx";
import Footer from "../blocks/Footer.tsx";

import { BrowserRouter as useLocation, Navigate } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const Lebenslauf = () => {
  // const query = useQuery();
  // const token = query.get("access");
  // const validToken = process.env.REACT_APP_TOKEN || "b3wrbng!";

  // token === validToken ?
  return (
    <div>
      <Navbar />
      <CV />
      <Skills />
      <HardSkills />
      <SoftSkills type="lebenslauf" />
      <Images />
      <Footer showImpressum={true} />
    </div>
  );
  // : (
  //   <Navigate to="/" />
  // );
};

export default Lebenslauf;
