import React from "react";
import Navbar from "../blocks/Navbar.tsx";
import Footer from "../blocks/Footer.tsx";

const Impressum = () => {
  return (
    <div>
      <Navbar />
      <div className="top-container flex-column-m">
        <h1 className="heading-2 text-primary">Impressum</h1>

        <h3 className="heading-6">Angaben gemäß § 5 TMG: </h3>
        <p className="p-medium">
          Julia Hellmann <br />
          <strong>Kontakt:</strong> Telefon: 0175 2496050 <br />
          <strong>E-Mail:</strong> hallo@juliahellmann.de
        </p>
        <h3 className="heading-6">
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
        </h3>
        <p className="p-medium">Julia Hellmann</p>
        <h2 className="heading-3">Haftung für Inhalte </h2>
        <p className="p-medium">
          Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf
          diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
          bis 10 TMG bin ich jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
          von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
          entfernen.
        </p>
        <h2 className="heading-3">Haftung für Links</h2>
        <p className="p-medium">
          Meine Website enthält Links zu externen Websites Dritter, auf deren
          Inhalte ich keinen Einfluss habe. Deshalb kann ich für diese fremden
          Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
          Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
          verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
          Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
          Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine
          permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Links
          umgehend entfernen.
        </p>
        <h2 className="heading-3">Urheberrecht</h2>
        <p className="p-medium">
          Die durch mich erstellten Inhalte und Werke auf diesen Seiten
          unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
          Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
          Grenzen des Urheberrechtes bedürfen meiner schriftlichen Zustimmung.
          Downloads und Kopien dieser Seite sind nur für den privaten, nicht
          kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
          nicht von mir erstellt wurden, werden die Urheberrechte Dritter
          beachtet. Insbesondere werden Inhalte Dritter als solche
          gekennzeichnet. Solltest du trotzdem auf eine Urheberrechtsverletzung
          aufmerksam werden, bitte ich um einen entsprechenden Hinweis. Bei
          Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte
          umgehend entfernen.
        </p>
        <h2 className="heading-3">Datenschutz beim Kontaktformular</h2>
        <p className="p-medium">
          Die von Ihnen im Kontaktformular eingegebenen Daten werden
          ausschließlich zur Bearbeitung Ihrer Anfrage verarbeitet.
        </p>
      </div>
      <Footer showImpressum={false} />
    </div>
  );
};

export default Impressum;
