import React from "react";

// interface Props {
//   headline: string;
//   subline?: string;
//   text: string;
//   team: string[];
//   kontext: string;
// }

const ProjectDetails = ({ team, kontext }) => {
  return (
    <div className="project-details--container flex-column-m">
      <div className="flex-column-xs">
        <h3 className="heading-6">Team</h3>
        <ul className="p-small project-details--list">
          {team.map((team) => {
            return <li key={team}>{team}</li>;
          })}
        </ul>
      </div>
      <div className="flex-column-xs">
        <h3 className="heading-6">Kontext</h3>
        <ul className="p-small project-details--list">
          {kontext.map((kontext) => {
            return <li key={kontext}>{kontext}</li>;
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProjectDetails;
