import React from "react";
import Skill from "../Skill.tsx";
import skillData from "../../data/skillData.json";

const HardSkills = () => {
  const programme = skillData
    .filter((c) => c.category === "Programme")
    .map((c) =>
      c.items.map((item) => {
        return (
          <Skill
            key={item.skill}
            skill={item.skill}
            knowledge={item.level}
            showLogo={true}
            logo={item.logo}
          />
        );
      })
    );

  const cms = skillData
    .filter((c) => c.category === "CMS")
    .map((c) =>
      c.items.map((item) => {
        return (
          <Skill
            key={item.skill}
            skill={item.skill}
            knowledge={item.level}
            showLogo={true}
            logo={item.logo}
          />
        );
      })
    );

  const code = skillData
    .filter((c) => c.category === "Code")
    .map((c) =>
      c.items.map((item) => {
        return (
          <Skill
            key={item.skill}
            skill={item.skill}
            knowledge={item.level}
            showLogo={true}
            logo={item.logo}
          />
        );
      })
    );

  return (
    <div className="container flex-column-l">
      <h2 className="heading-2">Hard Skills</h2>

      <h3 className="heading-3">Programme</h3>
      <div className="hard-skills--set flex-row-m">{programme}</div>

      <h3 className="heading-3">Content Management Systeme (CMS)</h3>
      <div className="hard-skills--set flex-row-m">{cms}</div>

      <h3 className="heading-3">Programmiersprachen</h3>
      <div className="hard-skills--set flex-row-m">{code}</div>
    </div>
  );
};

export default HardSkills;
