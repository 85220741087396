import React from "react";
import CardSimple from "../CardSimple.tsx";

const Skills = () => {
  return (
    <div className="skills container flex-column-l">
      <h2 className="heading-2">Meine Kenntnisbereiche</h2>
      <div className="skills--container">
        <div className="skills--list flex-column-m">
          <CardSimple
            title="UX / UI Design"
            text="Recherche, Konzeptionierung und Ausarbeitung von Layouts"
          />
          <CardSimple
            title="Code"
            text="Programmierung von Frontend-Anwendungen"
          />
          <CardSimple
            title="Motion Design"
            text="Erstellen animierter Grafiken und Zusammenschnitt von Videos"
          />
        </div>

        <img className="skills--img" src="/images/Julia.png" alt="Julia" />
      </div>
    </div>
  );
};

export default Skills;
