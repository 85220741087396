import React from "react";
import Popup from "../Popup.tsx";
import Navbar from "../blocks/Navbar.tsx";
import Hero from "../blocks/Hero.tsx";
import ProjectsOverview from "../blocks/ProjectsOverview.tsx";
import Skills from "../blocks/Skills.tsx";
import HardSkills from "../blocks/HardSkills.tsx";
import SoftSkills from "../blocks/SoftSkills.tsx";
import Footer from "../blocks/Footer.tsx";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Popup />
      <Hero />
      <ProjectsOverview />
      <Skills />
      <HardSkills />
      <SoftSkills type="home" />
      <Footer showImpressum={true} />
    </div>
  );
};

export default Home;
