import React from "react";
import classNames from "classnames";

interface Props {
  text: string;
  isActive?: boolean;
  type?: "search" | "default";
  handleClick?: VoidFunction;
}

const Label = ({ text, isActive, type, handleClick }: Props) => {
  const classes = classNames("p-small flex-row-xs label", {
    "label--search": type === "search",
    "label--active": isActive,
  });

  return (
    <div className={classes} onClick={handleClick}>
      <div>{text}</div>
      {isActive && (
        <img
          className="label--cancel"
          src="/images/Icons/cancel.png"
          alt="löschen"
        />
      )}
    </div>
  );
};

export default Label;
