import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar">
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? "navbar--link-active" : "navbar--links"
        }
      >
        <img className="navbar--logo" src="/logo512.png" alt="Logo"></img>
      </NavLink>
      <ul className="navbar--list flex-row-l heading-5">
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "navbar--link-active" : "navbar--links"
            }
          >
            Start
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/projekte"
            className={({ isActive }) =>
              isActive ? "navbar--link-active" : "navbar--links"
            }
          >
            Projekte
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
