import React from "react";
import CardSimple from "../CardSimple.tsx";
import Skill from "../Skill.tsx";
import skillData from "../../data/skillData.json";
import Button from "../ui/Button.tsx";

const SoftSkills = ({ type }) => {
  let content;

  const sprachen = skillData
    .filter((c) => c.category === "Sprachen")
    .map((c) =>
      c.items.map((item) => {
        return (
          <Skill
            key={item.skill}
            skill={item.skill}
            knowledge={item.level}
            showLogo={false}
          />
        );
      })
    );

  if (type === "home") {
    content = (
      <div className="flex-column-m soft-skills--content">
        <h2 className="heading-2">Ausbildung und Arbeit</h2>
        <CardSimple
          title="Bachelor of Arts"
          text="Höchster Bildungsabschluss"
        />
        <CardSimple title="UX / UI Designer" text="Letzte Tätigkeit" />
        {/* <Button label="Mehr erfahren" type="fill" link="lebenslauf" /> */}
      </div>
    );
  } else if (type === "lebenslauf") {
    content = (
      <div className="flex-column-m soft-skills--content">
        <h3 className="heading-3">Sprachkenntnisse</h3>
        <div className="hard-skills--set flex-row-m">{sprachen}</div>
      </div>
    );
  }

  return (
    <div className="soft-skills">
      <div className="soft-skills--container flex-column-m">
        <h2 className="heading-3">Soft Skills</h2>
        <ul className="p-medium flex-column-s">
          <li>Konstruktive Zusammenarbeit im Team</li>
          <li>Fähigkeit, komplexe Sachverhältnisse einfach zu erklären</li>
          <li>Motivation gemeinsame Ziele zu erreichen</li>
          <li>Selbstständiges Zeitmanagement</li>
          <li>Fähigkeit, Prioritäten zu setzen</li>
          <li>Hohe Lernbereitschaft</li>
        </ul>
      </div>
      {content}
    </div>
  );
};

export default SoftSkills;
